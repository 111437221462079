import React, { useState} from 'react';


const FormAdh = (props) => {

    const formik= props.formik.formik;

    const [selectProfils,setSelectProfils] = useState({...props.formik.selectProfils});

    //formatage nom
    const nomBlur= (e)=>{
        formik.handleBlur(e);
        if(!formik.errors.nom && e.target.value){
            formik.values.nom= e.target.value.toUpperCase();
        }
    }

    //Formatage n° de téléphone nn nn nn nn nn
    const telephoneBlur= (e)=>{
        formik.handleBlur(e);
        if(!formik.errors.telephone && e.target.value){
            let telin= e.target.value
            console.log(telin)
            let tel= telin.replace(/\s+/g, '');
            console.log(tel);
            formik.values.telephone= tel.substr(0,2) + ' '+ tel.substr(2,2)+' '+tel.substr(4,2)+' '+tel.substr(6,2)+' '+tel.substr(8,2);
        }
    }


    return (
        <div id='adhesion' className='col-6 offset-2'>
            <form data-form-type="other" className='mb-4' onSubmit={formik.handleSubmit}>
                    <div className="mb-2">
                        <label for="email" className="form-label">Email</label>
                        <input type="email" className="form-control" id="email" placeholder="Votre email"
                            name='email'
                            onChange={formik.handleChange}
                            value={formik.values.email}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.email && formik.errors.email && <span className='text-danger ms-1'>{formik.errors.email}</span>}
                    </div>
                    <div className="mb-2">
                        <label for="nom" className="form-label">Nom</label>
                        <input type="text" className="form-control"  id="nom" placeholder="Votre nom"
                            name='nom'
                            onChange={formik.handleChange}
                            value={formik.values.nom}
                            onBlur={nomBlur}
                        />
                        {formik.touched.nom && formik.errors.nom && <span className='text-danger ms-1'>{formik.errors.nom}</span>}
                    </div>
                    <div className="mb-2">
                        <label for="prenom" className="form-label">Prenom</label>
                        <input type="prenom" className="form-control" id="prenom" placeholder="Votre prenom"
                            name='prenom'
                            onChange={formik.handleChange}
                            value={formik.values.prenom}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.prenom && formik.errors.prenom && <span className='text-danger ms-1'>{formik.errors.prenom}</span>}
                    </div>
                    <div className="mb-2">
                        <label for="dateNaissance" className="form-label">Date de naissance</label>
                        <input type="date" className="form-control" id="dateNaissance" placeholder="Votre dateNaissance"
                            name='dateNaissance'
                            onChange={formik.handleChange}
                            value={formik.values.dateNaissance}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.dateNaissance && formik.errors.dateNaissance && <span className='text-danger ms-1'>{formik.errors.dateNaissance}</span>}
                    </div>
                    <div className="mb-2">
                        <label for="telephone" className="form-label">Téléphone</label>
                        <input type="text" className="form-control" id="telephone" placeholder="format nnnnnnnnn sans espace"
                            name='telephone'
                            onChange={formik.handleChange}
                            value={formik.values.telephone}
                            onBlur={telephoneBlur}
                        />
                        {formik.touched.telephone && formik.errors.telephone && <span className='text-danger ms-1'>{formik.errors.telephone}</span>}
                    </div>
                    <div className="mb-2">
                        <label for="adresse" className="form-label">Adresse</label>
                        <input type="adresse" className="form-control" id="adresse" placeholder="Votre adresse"
                            onChange={formik.handleChange}
                            value={formik.values.adresse}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.adresse && formik.errors.adresse && <span className='text-danger ms-1'>{formik.errors.adresse}</span>}
                    </div>
                    <div className="mb-2">
                        <label for="complements" className="form-label">complements</label>
                        <input type="complements" className="form-control" id="complements" 
                            name='complements'
                            onChange={formik.handleChange}
                            value={formik.values.complements}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.complements && formik.errors.complements && <span className='text-danger ms-1'>{formik.errors.complements}</span>}
                    </div>

                    <div className="mb-2">
                        <label for="codePostal" className="form-label">Code postal</label>
                        <input type="codePostal" className="form-control col-2" id="codePostal" placeholder="Code postal"aria-describedby="emailHelp"
                            name='codePostal'
                            onChange={formik.handleChange}
                            value={formik.values.codePostal}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.codePostal && formik.errors.codePostal && <span className='text-danger ms-1'>{formik.errors.codePostal}</span>}
                    </div>
                    <div className="mb-2">
                        <label for="ville" className="form-label">ville</label>
                        <input type="ville" className="form-control" id="ville" placeholder="Ville"aria-describedby="emailHelp"
                            name='ville'
                            onChange={formik.handleChange}
                            value={formik.values.ville}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.ville && formik.errors.ville && <span className='text-danger ms-1'>{formik.errors.ville}</span>}
                    </div>
                    {
                        selectProfils.current.OKMajProfil &&
                        <div className='mb-2'>
                            <label for='idProfil' className='form-label'>Profil</label>
                            <select name='idProfil' id='idprofil' className='form-select' 
                                onChange={formik.handleChange}
                                value={formik.values.idProfil}
                                onBlur={formik.handleBlur}
                            >
                                {
                                    selectProfils.current.profils.map((profil)=>{
                                        return(
                                            <option value={profil.idProfil} selected={profil.idProfil===formik.values.idProfil ? 'selected' : ''}>
                                                {profil.profil}
                                            </option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    }
                    <div className='row'>
                        <button id='valider' type='submit' className="button btn-light col-3 mt-3" onClick={formik.handleSubmit}>
                        Enregister</button>
                    </div>
            </form>
            
        </div>
    );
};

export default FormAdh;